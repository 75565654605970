// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "bootstrap"
import "@fortawesome/fontawesome-free/css/all"

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

// The stylesheet location we created earlier
require("../stylesheets/application.scss")

require("jquery")

/* You may have a few 'require' lines above this already */

require("@popperjs/core")


// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
})

import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import 'tablesorter'
import Cookies from 'js-cookie';
window.Cookies = Cookies;

$(document).on('turbolinks:load', () => {
  $("#eventriders-table").tablesorter({ sortReset   : true })
})

window.addEventListener('DOMContentLoaded', () => {
  $('#rider-select').select2()
})

$(".card").bind("DOMSubtreeModified", function() {
    alert("tree changed");
});
